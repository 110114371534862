import ThankYou from "../components/ThankYou";
import FLQr from "../assets/images/fl-qr.png";
import { ReactComponent as Logo} from '../assets/images/fl_logo.svg'


function ThankYouInfo(){

    const titleStyle = {
        textAlign: 'center'
    }
    return(
        <div className="ty-info">
            <div className="title" style={titleStyle}>
                <h1>Thank You!</h1>
            </div>
            <ThankYou cardTitle="Your Response Has Been Submitted" cardContent="In the meantime, scan the QR code to visit our website!"  qrCodeClass="qr-code-border" qrCode={FLQr} />
            <Logo className="fl-logo" style={{position:'absolute',height:'100px',width:'100px',right:'60px',bottom:'105px'}} />
        </div>
    )
}

export default ThankYouInfo;