import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DebitCardLogo } from '../assets/images/debit_card_icon.svg';
import VenmoLogo from '../assets/images/venmo-logo.png';

import Navbar from '../components/Navbar'

function PaymentMethod() {
    const [paymentMethod, setPaymentMethod] = useState('');
    const navigate = useNavigate();

    const handlePaymentMethodSelect = (method) => {
        setPaymentMethod(method);
    };

    const handleSubmit = () => {
        if (paymentMethod === 'debit-card') {
            navigate('/debit-card-donation');
        } else if (paymentMethod === 'venmo') {
            navigate('/thank-you-donation');
        }
    };

    return (
        <div className='payment-method'>
            <Navbar />
            <div className='title'>
                <h1>Make a Donation</h1>
            </div>
            <div className='buttons'>
                <h5>Payment Options</h5>
                <div className='payment-options'>
                    <div 
                        className={`debit-card-option ${paymentMethod === 'debit-card' ? 'selected' : ''}`} 
                        onClick={() => handlePaymentMethodSelect('debit-card')}
                    >
                        <DebitCardLogo alt="Debit Card" />
                        <p>Debit/Credit<br />Card</p>
                    </div>
                    <div 
                        className={`venmo-option ${paymentMethod === 'venmo' ? 'selected' : ''}`} 
                        onClick={() => handlePaymentMethodSelect('venmo')}
                    >
                        <img src={VenmoLogo} style={{ width: '100px' }} alt="Venmo" />
                        <p>Scan & Pay with Venmo</p>
                    </div>
                </div>
                {paymentMethod && (
                    <button onClick={handleSubmit}>SUBMIT</button>
                )}
            </div>
        </div>
    );
}

export default PaymentMethod;
