import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '../components/Navbar'

function Donation() {
  const [inputFormData, setInputFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [showError, setShowError] = useState(false); // New state for error visibility
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedDonationType, setSelectedDonationType] = useState('sweet-case');
  const [selectedDonationAmount, setSelectedDonationAmount] = useState(30);
  const history = useNavigate();
  const [showCustomAmount, setShowCustomAmount] = useState(false); // New state for showing custom amount input

  function handleFormSubmit() {
    if (inputFormData.firstName && inputFormData.lastName && inputFormData.email && validateEmail(inputFormData.email)) {
      const completeData = {
        ...inputFormData,
        donationType: selectedDonationType,
        donationAmount: selectedDonationAmount,
      };
      localStorage.setItem('donationData', JSON.stringify(completeData));
      console.log('InputForm data:', completeData);
      history({
        pathname: '/payment',
        state: completeData,
      });
    } else {
      setShowError(true); // Show error message if form is incomplete
    }
  }

  function handlePhoneChange(event) {
    const rawPhone = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedPhone = '';

    if (rawPhone.length >= 3) {
      formattedPhone = `(${rawPhone.slice(0, 3)})`;
    }
    if (rawPhone.length >= 4 && rawPhone.length <= 6) {
      formattedPhone += ` - ${rawPhone.slice(3, 6)}`;
    }
    if (rawPhone.length > 6) {
      formattedPhone += ` - ${rawPhone.slice(6, 10)}`;
    }

    setInputFormData({ ...inputFormData, phone: formattedPhone });
  }


  function handleFirstNameChange(event) {
    setInputFormData({ ...inputFormData, firstName: event.target.value });
  }

  function handleLastNameChange(event) {
    setInputFormData({ ...inputFormData, lastName: event.target.value });
  }

  function handleEmailChange(event) {
    setInputFormData({ ...inputFormData, email: event.target.value });
  }

  function handlePhoneChange(event) {
    const phone = event.target.value.replace(/\D/g, '').substring(0, 10); // Remove non-numeric characters and limit to 10 characters
    setInputFormData({ ...inputFormData, phone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') }); // Format as (xxx) - xxx - xxxx
  }

  function handleButtonClick(event) {
    const clickedButton = event.currentTarget;
    setSelectedDonationType(clickedButton.children[0].getAttribute('data-type'));
    setSelectedDonationAmount(clickedButton.children[0].getAttribute('data-amount'));
    const isCustomButton = clickedButton.children[0].getAttribute('data-type') === 'custom';
    setShowCustomAmount(isCustomButton); // Show or hide custom amount input based on button type

    if (selectedButton) {
      selectedButton.classList.remove('selected');
    }

    clickedButton.classList.add('selected');
    setSelectedButton(clickedButton);
  }

  function handleCustomAmountChange(event) {
    const customAmount = parseFloat(event.target.value) || 0;
    setSelectedDonationAmount(customAmount);
  }

  function validateEmail(email) {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }

  return (
    <div className="donation">
      <Navbar />
      <div className="title">
        <h1>Make A Donation</h1>
      </div>
      <div className="card">
        <div className="card-title">
          <h5>Your Contact Information*</h5>
        </div>
        <div className="card-fields">
          <input
            type="text"
            onChange={handleFirstNameChange}
            placeholder="First Name*"
            required
          />
          <input
            type="text"
            onChange={handleLastNameChange}
            placeholder="Last Name*"
            required
          />
          <input
            type="email"
            onChange={handleEmailChange}
            placeholder="Email*"
            required
          />
          <input
          type="tel"
          value={inputFormData.phone} // Add this line to update input field with the formatted value
          onChange={handlePhoneChange}
          placeholder="Phone Number" // Updated placeholder to show format
        />
        </div>
        {showError && (
          <div className="error-message" style={{ color: 'red' }}>
            Please fill out all required fields (*).
          </div>
        )}
        <div className="card-sub-title">
          <h5>Donation Amount*</h5>
        </div>
        <div className="donation-buttons">
        <div onClick={handleButtonClick} className="buttons">
        <button
          data-type="hygene"
          type="button"
          className=""
          data-amount="15"
        >
          <h5>$15</h5>
          <p>Sponsor a Hygiene Kit</p>
        </button>
      </div>
      <div onClick={handleButtonClick} className="buttons">
        <button
          data-type="sweet-case"
          type="button"
          className="most-popular selected"
          data-amount="30"
        >
          <h5>$30</h5>
          <p>Gift a <br />Sweet Case Duffle Bag</p>
        </button>
      </div>
      <div onClick={handleButtonClick} className="buttons">
        <button
          data-type="education"
          type="button"
          className=""
          data-amount="75">
          <h5>$75</h5>
          <p>Support Educational Service</p>
        </button>
      </div>
      <div onClick={handleButtonClick} className="buttons">
        <button
          data-type="bicycle"
          type="button"
          className=""
          data-amount="150"
        >
          <h5>$150</h5>
          <p>Provide A Bicycle & Helmet</p>
        </button>
      </div>
      <div onClick={handleButtonClick} className="buttons">
        <button
          data-type="reunification"
          type="button"
          className=""
          data-amount="650"
        >
          <h5>$650</h5>
          <p>Reunite A Set Of Siblings At Disneyland</p>
        </button>
      </div>
      <div onClick={handleButtonClick} className="buttons">
          <button data-type="custom" type="button" className="" data-amount="0">
            {selectedButton &&
            selectedButton.children[0].getAttribute('data-type') === 'custom' ? (
              <div className="custom-amount-input">
                <input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Custom Amount"
                  onChange={handleCustomAmountChange}
                  style={{width:'75%',borderRadius:'5px',border:'solid 1px gray',marginBottom:'18px'}}
                />

                <p style={{}}>Enter Custom Amount</p>
              </div>
            ) : (
              <>
                <h5>&nbsp;</h5>
                <p>Enter Custom Amount<br />&nbsp;</p>
              </>
            )}
            
          </button>
        </div>
      </div>
    <div className="sub-button">
      <button type="button" onClick={handleFormSubmit}>
        Continue
      </button>
    </div>
  </div>
  
</div>
);
}

export default Donation;
