
import Back from '../assets/images/back_arrow.png'
import { Link } from 'react-router-dom'


function BackArrow(props){
    return(
        <div className='back-arrow'>
            <Link to={props.route}>
                <img src={Back} alt='back arrow' height='50px' width='50px'/>
            </Link>
        </div>
    )
}

export default BackArrow