import React from 'react';
import MainMenu from "../components/MainMenu";
import { ReactComponent as Logo } from '../assets/images/fl_logo.svg';

function Home(){
    return(
        <div>
             <Logo style={{width:'100px'}}/>
            <MainMenu />
        </div>
    )
}

export default Home;