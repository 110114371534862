import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Donation from './pages/Donation';
import Information from './pages/Information';
import ThankYouInfo from './pages/ThankYouInfo';
import ThankYouDonation from './pages/ThankYouDonation';
import DebitCardDonation from './pages/DebitCard';
import PaymentMethod from './pages/Payment';
import ThankYouPage from './pages/ThankYouPage';
import React from 'react';

import './App.css';


function App() {
  return (
    <Router>
     
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/information" element={<Information />} />
          <Route path="/thank-you-info" element={<ThankYouInfo />} />
          <Route path="/thank-you-donation" element={<ThankYouDonation />} />
          <Route path='/payment' element={<PaymentMethod />} />
          <Route path='debit-card-donation' element={<DebitCardDonation />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
