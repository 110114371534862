import { useState, useEffect } from 'react';
import axios from 'axios';
import DropIn from 'braintree-web-drop-in-react';
import Heart from '../assets/images/heart.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'

function DebitCard() {
  const storedData = localStorage.getItem('donationData');
  const formData = storedData ? JSON.parse(storedData) : null;

  const [clientToken, setClientToken] = useState(null);
  const [dropinInstance, setDropinInstance] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCsrfToken() {
      console.log('getting csrf Token');
      const response = await axios.get('https://api.fosterlove.com/checkout/generate-client-token/');
      const csrfToken = Cookies.get('csrftoken');
      setCsrfToken(csrfToken);
      console.log('CSRF Token:', csrfToken);
    }

    getCsrfToken();
  }, []);

  useEffect(() => {
    async function fetchClientToken() {
      try {
        console.log('trying to get client token');
        const response = await fetch('https://api.fosterlove.com/checkout/client-token/');
        const jsonResponse = await response.json();
        const clientToken = jsonResponse.client_token;
        setClientToken(clientToken);
      } catch (err) {
        console.error('Failed to fetch client token:', err);
      }
    }

    fetchClientToken();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('submitting form');

    setLoading(true);
    setDisabled(true);

    const { nonce } = await dropinInstance.requestPaymentMethod();
    const transactionData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      amount: formData.donationAmount,
      program: formData.donationType,
      billing_address: '560 Lambert Rd',
      billing_city_town: 'Brea',
      billing_state: 'California',
      nonce: nonce,
    };

    console.log('submitted data:', JSON.stringify(transactionData));

    try {
      const response = await axios.post('https://api.fosterlove.com/checkout/mobile-booth-donation/', transactionData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });

      console.log(response.status);
      setPaymentSuccess(true);

      if (response.status === 200) {
        localStorage.clear();
        navigate('/thank-you');
      }
      else{
        console.error('Promise Error', error.response.status);
        setError(true);
      }
    } catch (error) {
      console.error('Promise Error', error.response.status);
      setError(true);
    }

    setLoading(false);
    setDisabled(false);
  };

  return (
    <div className='debit-card-payment'>
      <Navbar />
      {loading && (
        <div className='loading-animation'>
          <img src={Heart} alt='Heart' />
        </div>
      )}

      <div className='title'>
        <h1>Make A Donation</h1>
      </div>

      {clientToken && (
        <DropIn
          options={{
            authorization: clientToken,
            card: {
              cardholderName: false,
            },
          }}
          onInstance={(instance) => setDropinInstance(instance)}
        />
      )}

      <button type="submit" onClick={handleSubmit} disabled={disabled}>
        {loading ? 'Processing...' : 'Pay with Debit Card'}
      </button>

      {paymentSuccess && (
        <div className="alert alert-success">
          Thank you for your donation!
        </div>
      )}

      {error && (
        <div className="alert alert-danger">
          Something went wrong with your payment, please try another method of payment.
        </div>
      )}
    </div>
  );
}

export default DebitCard;
