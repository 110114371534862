import React from 'react';
import { ReactComponent as Dollar} from '../assets/images/v2/make_a_donation.svg'
import { ReactComponent as Bulb } from '../assets/images/v2/request_more_information.svg'
import { Link } from 'react-router-dom'


function MainMenu(){
    return(
        <div className="main-menu">
            <div className="title">
                <h1>Welcome!</h1>
                <h1> What Would You Like To Do Today?</h1>
            </div>
            <div className="buttons">
            <Link to="/donation">
                <div className='dollar'>
                        <Dollar />
                        <h4>Make a</h4>
                        <h4 className='bottom'> Donation</h4>
                </div>
            </Link>
            <Link to="/information">
                <div className='bulb'>
                        <Bulb />    
                        <h4>Request More</h4>
                        <h4 className='bottom'>Information</h4>    
                </div>
            </Link>
            </div>
        </div>
    )
}

export default MainMenu;