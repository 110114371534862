import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ThankYouImage from '../assets/images/v2/thank_you_banner.jpg'; // Adjust the path according to your project structure

function ThankYouPage() {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  };

  return (
    <div className="thank-you-page-debit">
      <div className="header">
        <Navbar />
      </div>
      <h1>Thank You For Changing a <br />Life Today!</h1>
      <img src={ThankYouImage} alt="Thank You" className="thank-you-image" />
      <button onClick={handleReturnHome} className="return-button">
        Return to Home Screen
      </button>
    </div>
  );
}

export default ThankYouPage;
