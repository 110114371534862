import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/fl_logo.svg';
import { FaHome } from 'react-icons/fa'; // Importing Home icon from react-icons
import BackArrow from './BackArrow';

function Navbar() {
  const location = useLocation();
  const navBarStyle = {
    width: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  return (
    <div className='top-bar'>
      {location.pathname !== '/' && (
        <div style={navBarStyle}>
            <div className='logo'>
                <Logo />
            </div>
        
          <BackArrow route={-1} />

          <div className='homeButton'>
          <Link to="/">
            <FaHome style={{ marginRight: '10px', cursor: 'pointer' }} />
          </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;

