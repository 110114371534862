import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThankYouImage from '../assets/images/v2/thank_you_banner.jpg'; 
import VenmoQRCode from '../assets/images/venmo-qr.png'; 
import Logo from '../assets/images/fl_logo.svg'; 
import VenmoLogo from '../assets/images/venmo-logo.png';

function ThankYouVenmoPage() {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate('/');
  };

  return (
    <div className="thank-you-page-venmo">
      <div className="header">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      <h1>Thank You For Changing a Life Today!</h1>
      <div className="card">
        <div className="image-container">

        </div>
        <div className="qr-container">
          <img src={VenmoLogo} alt="Venmo Logo" className="venmo-logo" />
          <img src={VenmoQRCode} alt="Venmo QR Code" className="qr-code" />
          <button onClick={handleReturnHome} className="return-button">
            RETURN TO HOME SCREEN
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThankYouVenmoPage;

