import { Link } from "react-router-dom";


function ThankYou(props){
    return(
        <div className="thank-you-card">
            <div className="card">
                <div className="card-title">
                    <h5>{props.cardTitle}</h5>
                    <p>{props.cardContent}</p>
                </div>

                <img src={props.imageSrc} className="venmo-logo" alt="" />
                <div className={props.qrCodeClass}>
                    <img src={props.qrCode} alt="" />
                </div>
                <div className="return-home">
                    <Link to="/">
                        <button>Return To Home Screen</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ThankYou;