import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Volunteering } from '../assets/images/v2/v_icon.svg';
import { ReactComponent as TeamBuilding } from '../assets/images/v2/tb_icon.svg';
import { ReactComponent as ServiceActivity} from '../assets/images/v2/sa_icon.svg';
import axios from 'axios'
import Navbar from '../components/Navbar';



function Information() {
  const [selected, setSelected] = useState({
    volunteering: { active: false, value: '' },
    teamBuilding: { active: false, value: '' },
    serviceActivity: { active: false, value: '' }
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  console.log(errorMessage);

  function handleIconClick(key) {
    setSelected(prevState => ({ ...prevState, [key]: !prevState[key] }));
  }

  function handleFirstNameChange(event) {
    setFirstName(event.target.value);
  }

  function handleLastNameChange(event) {
    setLastName(event.target.value);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePhoneChange(event) {
    const formattedPhone = event.target.value.replace(/\D/g, '').substring(0, 10); // Remove non-numeric characters and limit to 10 characters
    setPhone(formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')); // Format as (xxx) xxx-xxxx
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = {
      firstName,
      lastName,
      email,
      phone
    };
    const selectedOptions = {
      volunteering: selected.volunteering ? null : 'Volunteering',
      teamBuilding: selected.teamBuilding ? null : 'Team Building',
      serviceActivity: selected.serviceActivity ? null : 'Service Activity'
    };
    const data = {
      ...formData,
      ...selectedOptions
    };
    console.log(data);

    try {
      const response = await axios.post('https://api.fosterlove.com/checkout/mobile-booth-information/', data);
      console.log(response);
      if (response.status === 200) {
        navigate("/thank-you-info");
      } else {
        setErrorMessage('Error submitting form');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error submitting form');
    }
  }  

  return (
    <div className='information'>
      <Navbar />
      <div className='title'>
        <h1>Request More Information</h1>
      </div>
      <div className='card'>
        <div className='card-title'>
          <h5>Your Contact Information</h5>
        </div>
        <div className='card-fields'>
          <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="First Name*" />
          <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="Last Name*" />
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Email*" />
          <input type="tel" value={phone} onChange={handlePhoneChange} placeholder="Phone Number" />
        </div>
        <div className='card-sub-title'>
          <h5>Learn More About...</h5>
        </div>
        <div className='card-icons'>
          <div value='volunteering' className={`icon-item ${selected.volunteering ? '' : 'selected'}`} onClick={() => handleIconClick('volunteering')}>
            <h6>Volunteering</h6>
            <Volunteering />
          </div>
          <div value='team-building' className={`icon-item ${selected.teamBuilding ? '' : 'selected'}`} onClick={() => handleIconClick('teamBuilding')}>
            <h6>Team Building</h6>
            <TeamBuilding />
          </div>
          <div value='service-activity' className={`icon-item ${selected.serviceActivity ? '' : 'selected'}`} onClick={() => handleIconClick('serviceActivity')}>
            <h6>Service Activity</h6>
            <ServiceActivity />
          </div>
        </div>
        <div className='sub-button'>
          <button type="submit" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Information;
